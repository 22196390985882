import { useState, useEffect } from "react";
import { FaEye, FaEdit, FaTrash, FaSort } from "react-icons/fa";
import { toast } from "react-toastify";
import axiosClient from "../../axios";
import { useAuthContext } from "../../hooks/useAuthContext";
import { useProductsContext } from "../../hooks/useProductsContext";
import MoneyFormat from "../Utils/MoneyFormat";

const ListOfProducts = ({ goToProduct, goToEditProduct }) => {
    const { products, dispatch } = useProductsContext();
    const { user } = useAuthContext();

    const [searchQuery, setSearchQuery] = useState("");
    const [sortOrder, setSortOrder] = useState("desc");
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const [filteredProducts, setFilteredProducts] = useState([]);

    useEffect(() => {
        const fetchProducts = async () => {
            try {
                const response = await axiosClient.get("product");
                // console.log(444, response.data)
                if (response.status === 200) {
                    dispatch({
                        type: "SET_PRODUCTS",
                        payload: response.data,
                    });
                }
            } catch (error) {
                console.error(error);
            }
        };

        if (user) {
            fetchProducts();
        }
    }, [dispatch, user]);

    useEffect(() => {


        if (!products || products.length === 0) {
            setFilteredProducts([]);
            return;
        }
        let filtered = [...products];
        if (searchQuery) {
            filtered = filtered.filter((product) =>
                product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
                product.code.toLowerCase().includes(searchQuery.toLowerCase())
            );
        }

        // Default sorting by name alphabetically
        filtered = filtered.sort((a, b) => a.name.localeCompare(b.name));

        // Override with price sorting if sortOrder is specified
        if (sortOrder === "asc") {
            filtered = filtered.sort((a, b) => a.price_ngn - b.price_ngn);
        } else if (sortOrder === "desc") {
            filtered = filtered.sort((a, b) => b.price_ngn - a.price_ngn);
        }

        // // Search functionality
        // if (searchQuery) {
        //     filtered = products.filter((product) =>
        //         product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
        //         product.code.toLowerCase().includes(searchQuery.toLowerCase())
        //     );
        // }

        // // Sorting by price
        // if (sortOrder === "asc") {
        //     filtered = filtered.sort((a, b) => a.price_usd - b.price_usd);
        // } else {
        //     filtered = filtered.sort((a, b) => b.price_usd - a.price_usd);
        // }

        setFilteredProducts(filtered);
    }, [products, searchQuery, sortOrder]);

    // Pagination
    const totalItems = filteredProducts.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const paginatedProducts = filteredProducts.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handleDeleteClick = async (productID) => {
        try {
            const result = await axiosClient.delete(`product/delete/${productID}`);
            if (result.status === 200) {
                toast.success("Product deleted");
                dispatch({
                    type: "DELETE_PRODUCT",
                    payload: result.data,
                });
            }
        } catch (err) {
            toast.error("Something went wrong");
        }
    };

    const handleSortToggle = () => {
        setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    };

    const handlePageChange = (newPage) => {
        if (newPage > 0 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    const ActionColumn = user.role === "999" && (
        <th className="text-sm font-medium text-gray-900 px-6 py-4 text-center">Action</th>
    );

    return (
        <div className="p-4">
            <div className="flex flex-col space-y-4">
                <div className="flex flex-wrap items-center justify-between gap-4">
                    <input
                        type="text"
                        placeholder="Search by name or code..."
                        value={searchQuery}
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="px-4 py-2 border border-gray-300 rounded-md shadow-sm w-full md:w-1/3"
                    />
                    <div className="flex items-center space-x-4">
                        <label htmlFor="itemsPerPage" className="text-sm text-gray-600">
                            Items per page:
                        </label>
                        <select
                            id="itemsPerPage"
                            value={itemsPerPage}
                            onChange={(e) => setItemsPerPage(parseInt(e.target.value))}
                            className="px-2 py-1 border border-gray-300 rounded-md shadow-sm"
                        >
                            {[20, 30, 50].map((count) => (
                                <option key={count} value={count}>
                                    {count}
                                </option>
                            ))}
                        </select>
                    </div>
                    <button
                        onClick={handleSortToggle}
                        className="px-4 py-2 bg-blue-500 text-white rounded-md shadow-md hover:bg-blue-600 flex items-center space-x-2"
                    >
                        <span>Sort by Price</span>
                        <FaSort />
                    </button>
                </div>

                <div className="overflow-x-auto">
                    <table className="min-w-full table-auto border-collapse">
                        <thead className="bg-gray-100">
                            <tr>
                                <th className="px-6 py-3 text-left text-sm font-medium text-gray-900">S/N</th>
                                <th className="px-6 py-3 text-left text-sm font-medium text-gray-900">Image</th>
                                <th className="px-6 py-3 text-left text-sm font-medium text-gray-900">Name</th>
                                <th className="px-6 py-3 text-left text-sm font-medium text-gray-900">Code</th>
                                <th className="px-6 py-3 text-left text-sm font-medium text-gray-900">Price</th>
                                {ActionColumn}
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedProducts.map((prod, index) => (
                                <tr key={prod.id} className="border-b">
                                    <td className="px-6 py-4 text-sm text-gray-900">{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                    <td>
                                        <img className="w-12 h-12 rounded-full object-cover" src={prod.image} alt="Product" />
                                    </td>
                                    <td className="px-6 py-4 text-sm text-gray-900">{prod.name}</td>
                                    <td className="px-6 py-4 text-sm text-gray-900">{prod.code}</td>
                                    <td className="px-6 py-4 text-sm text-gray-900">
                                        <MoneyFormat
                                            amount={
                                                ["The FH Luggage", "The FH Luggage Set", "The Duffel Bag"].includes(prod.name)
                                                    ? prod.price_usd
                                                    : prod.price_ngn
                                            }
                                            currency={
                                                ["The FH Luggage", "The FH Luggage Set", "The Duffel Bag"].includes(prod.name)
                                                    ? "USD"
                                                    : "NGN"
                                            }
                                        />
                                    </td>
                                    {user.role === "999" && (
                                        <td className="px-6 py-4">
                                            <div className="flex items-center space-x-2">
                                                <button onClick={() => goToProduct(prod.id)} className="text-blue-500">
                                                    <FaEye />
                                                </button>
                                                <button onClick={() => goToEditProduct(prod.id)} className="text-green-500">
                                                    <FaEdit />
                                                </button>
                                                <button onClick={() => handleDeleteClick(prod.id)} className="text-red-500">
                                                    <FaTrash />
                                                </button>
                                            </div>
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Pagination Controls */}
                <div className="flex justify-between items-center mt-4">
                    <button
                        disabled={currentPage === 1}
                        onClick={() => handlePageChange(currentPage - 1)}
                        className="px-4 py-2 bg-gray-300 rounded-md shadow-md hover:bg-gray-400"
                    >
                        Previous
                    </button>
                    <span className="text-sm">
                        Page {currentPage} of {totalPages}
                    </span>
                    <button
                        disabled={currentPage === totalPages}
                        onClick={() => handlePageChange(currentPage + 1)}
                        className="px-4 py-2 bg-gray-300 rounded-md shadow-md hover:bg-gray-400"
                    >
                        Next
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ListOfProducts;
